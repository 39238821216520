<script lang="ts" module>
	export type TServer = {
		name: string;
		server_ip: string;
		server_port: number;
		server_players_current: number;
		server_players_max: number;
		server_map: string;
		server_game: string;
		server_order: number;
		server_status: number;
		display_name: string;
	};
</script>

<script lang="ts">

	import { Users2 } from 'lucide-svelte';
	import { toast } from 'svelte-sonner';
	interface Props {
		server: TServer;
	}

	let { server }: Props = $props();

	async function copyToClipboard(text: string) {
		try {
			await navigator.clipboard.writeText(`connect ${text}`);
			toast.success('Kopierad!');
		} catch (err) {
			toast.error(`Kunde inte kopiera: ${err}`);
			console.error('Failed to copy: ', err);
		}
	}
</script>

<div class="server">
	<div class="flex justify-between server-name">
		<span>{server.display_name}</span>
		<!-- <span class={server.server_status == 1 ? 'online-dot dot' : 'offline-dot dot'}></span> -->
	</div>

	<div class="image-container">
		<img
			src="https://playpark.se/maps/{server.server_map}.jpg"
			alt={`Map bild ${server.server_map}`}
			onerror={(e) => {
				const img = e.currentTarget as HTMLImageElement;
				img.src =
					'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
			}}
		/>
	</div>

	<span class="players"
		><Users2 class="mr-2" />
		{#if server.server_status == 1}
			{server.server_players_current} / {server.server_players_max}
		{:else}
			Offline
		{/if}
	</span>

	<button
		class="w-full"
		onclick={async () => await copyToClipboard(`${server.server_ip}:${server.server_port}`)}
	>
		<div class="server-ip">Kopiera IP</div>
	</button>
</div>

<style lang="scss">
	.server {
		background: #121216;
		margin: 16px;
		padding: 16px;
		padding-top: 8px;
		border-radius: 16px;
		color: #fff;
		max-width: 260px;

		.image-container {
			position: relative;
			width: 220px;
			height: 150px;
			border-radius: 16px;
			display: flex;
			background: rgb(21 21 26);
			align-items: center;
			justify-content: center;
			background: url(https://expatguideturkey.com/wp-content/uploads/2023/03/cs-go-sistem-gereksinimleri-721778-780x470.webp);
			background-size: cover;
		}

		img {
			border-radius: 16px;
			width: 100%;
			height: 100%;

			background: rgb(21 21 26 / 0%);
			text-align: center;
			line-height: 150px;
			position: absolute;
			top: 0px;
			left: 0px;
		}

		.server-name {
			margin-bottom: 8px;
			align-items: center;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
		}

		.players {
			display: flex;
			margin-top: 16px;
		}

		.server-ip {
			text-align: center;
			padding: 8px;
			border: 1px solid rgb(73, 73, 73);
			border-radius: 16px;
			margin-top: 16px;
			margin-bottom: 4px;

			&:hover {
				cursor: pointer;
				background: #262626;
			}
		}
	}
</style>
